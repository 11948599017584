<template>
  <div class="container">
    <div class="invite-module">
      <div class="invite-banner">
        <el-image
          class="fake-img"
          :src="ossUrl + '/images/invite-img/invite-banner2.png'"
          fit="scale-down"
        ></el-image>
        <div class="invite-info">
          <!-- <h3 class="ii-title">最高可得{{inviteFriend.award_money}}元现金红包</h3> -->
          <h3 class="ii-title">邀请好友得现金红包，上不封顶</h3>
          <p class="ii-descriptions">
            <span>您已成功邀请</span>
            <span class="color-style">{{inviteFriend.invite_valid_total}}</span>
            <span>人，累计获得现金</span>
            <span class="color-style">{{inviteFriend.invite_money}}</span>
            <span>元</span>
          </p>
        </div>
      </div>
      <div class="invite-details">
        <div class="invite-qrcode">
          <el-image
            class="fake-img"
            :src="ossUrl + '/images/invite-img/invite-code-bg.png'"
            fit="scale-down"
          ></el-image>
          <div class="qr-photo">
            <el-image
              class="fake-img"
              :src="inviteFriend.invite_qr_code"
              fit="scale-down"
            ></el-image>
          </div>
        </div>
        <div class="confirm">
          <div class="confirm-item">
            <el-button class="fake-btn" @click="saveCodeFun(inviteFriend.invite_qr_code)" >
              <span>保存二维码</span>
            </el-button>
          </div>
          <div class="confirm-item">
            <el-button class="fake-btn" @click="doCopy(hostUrl)" >
              <span>复制邀请链接</span>
            </el-button>
          </div>
        </div>
        <p class="activity-rules">
          <span class="ar-text" to="#" @click.stop.prevent="activityRulesFun">
            <span>活动规则</span>
            <i class="iconfont icon-arrow-right-1-icon"></i>
          </span>
        </p>
      </div>
      <div class="invite-main">
        <div class="invite-flow">
          <div class="flow-head">
            <div class="flow-title">
              <span class="title-text">邀请流程</span>
            </div>
          </div>
          <div class="flow-continer">
            <ul class="flow-form">
              <li class="ff-item">
                <div class="ff-icon">
                  <div class="icon-box" :style="'background-image: url(\'' + ossUrl + '/images/invite-img/invite-flow (1).png\')'">
                    <i class="iconfont icon-fuzhi"></i>
                  </div>
                  <p class="icon-name">复制链接</p>
                </div>
                <div class="ff-arrow">
                  <el-image
                    :src="ossUrl + '/images/invite-img/invite-flow-arrow2.png'"
                    fit="scale-down"
                  ></el-image>
                </div>
              </li>
              <li class="ff-item">
                <div class="ff-icon">
                  <div class="icon-box" :style="'background-image: url(\'' + ossUrl + '/images/invite-img/invite-flow (4).png\')'">
                    <i class="iconfont icon-fenxiangfangshi"></i>
                  </div>
                  <p class="icon-name">分享到微信好友/群</p>
                </div>
                <div class="ff-arrow">
                  <el-image
                    :src="ossUrl + '/images/invite-img/invite-flow-arrow2.png'"
                    fit="scale-down"
                  ></el-image>
                </div>
              </li>
              <li class="ff-item">
                <div class="ff-icon">
                  <div class="icon-box" :style="'background-image: url(\'' + ossUrl + '/images/invite-img/invite-flow (3).png\')'">
                    <i class="iconfont icon-dengluyonghuming"></i>
                  </div>
                  <p class="icon-name">好友登录成功</p>
                </div>
                <div class="ff-arrow">
                  <el-image
                    :src="ossUrl + '/images/invite-img/invite-flow-arrow2.png'"
                    fit="scale-down"
                  ></el-image>
                </div>
              </li>
              <li class="ff-item">
                <div class="ff-icon">
                  <div class="icon-box" :style="'background-image: url(\'' + ossUrl + '/images/invite-img/invite-flow (2).png\')'">
                    <i class="iconfont icon-qianbao"></i>
                  </div>
                  <p class="icon-name">成功购买商品</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <p class="copyright-tips">此活动最终解释权为佰团乐科技有限公司所有</p>
    </div>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'Invite',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {

  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {
      ossUrl: this.GLOBAL.ossUrl,
      facility: {}, // 当前设备
      inviteFriend: {},// 邀请好友数据
      hostUrl: window.location.host,// 当前域名+端口
    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {
    /**
     * 获取页面数据
     */
    getPageDataFun() {
      // 初始化-分享/邀请链接
      this.initHostUrlFun();
      // 获取邀请好友数据
      this.getInviteFriendFun();
      // 获取设备信息
      this.getFacilityInfo();
    },
    /**
     * 获取设备信息
     */
    getFacilityInfo() {
      let facility = this.myGetCurrentFacility();
      console.log('获取设备信息 facility == ',facility)
      this.facility = facility;
    },
    /**
     * 初始化-分享/邀请链接
     */
    initHostUrlFun() {
      // 个人信息请求
      this.myLoginInfoRequest().then((res) => {
        let resData = res.data.data;
        console.log('个人信息请求 resData == ',resData)
        let { id } = resData;
        // www.baituanle.com
        let baseHost = window.location.host + '/#/?invite_user_id=';
        let query = encodeURIComponent(id);
        this.hostUrl = baseHost + query;
        console.log('初始化-分享/邀请链接 this.hostUrl == ',this.hostUrl)
      });
    },
    /**
     * 获取邀请好友数据
     */
    getInviteFriendFun() {
      this.myRequest({
        method: 'post',
        url: '/api/user/invitefriend'
      }).then((res) => {
        let data = res.data.data;
        this.inviteFriend = data;
        console.log('获取邀请好友数据 this.inviteFriend == ',this.inviteFriend)
      })
    },

    /**
     * 查看活动规则
     */
    activityRulesFun: function() {
      let result = {
        name: '活动规则',
        html: this.inviteFriend.invite_agreement,
      };
      this.$alert(result.html,  result.name, {
        dangerouslyUseHTMLString: true,
        customClass: 'mesaage-alert-class'
      }).catch((err) => {

      });
    },
    /**
     * 保存二维码
     */
    saveCodeFun(url) {
      console.log('保存二维码')
      let { facility } = this.facility;
      if(facility && facility == 'mobile') {
        this.myMessage({
          // type: 'success',
          message: '手机端请长按保存图片！',
        });
        return;
      }
      // 保存图片到本地
      this.myDownloadIamge(url)
    },
    /**
     * 复制
     */
    doCopy: function (value) {
      const _this = this;
      this.$copyText(value).then(function (e) {
        console.log('doCopy 复制成功 e == ',e);
        _this.myMessage({
          type: 'success',
          message: '链接复制成功',
        });
      }, function (e) {
        console.log('doCopy 链接失败 e == ',e);
        _this.myMessage({
          type: 'error',
          message: '链接复制失败',
        });
      })
    },
  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {
    // 获取页面数据
    this.getPageDataFun();
  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  background: -webkit-linear-gradient(#DDD0BD , #FFFFFF); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#DDD0BD, #FFFFFF); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(#DDD0BD, #FFFFFF); /* Firefox 3.6 - 15 */
  background: linear-gradient(#DDD0BD , #FFFFFF); /* 标准的语法（必须放在最后） */
}


.invite-module {
  box-sizing: border-box;
  padding: 0 $theme-widht-whiteedge;
  margin: 0 auto;
  // width: $theme-view-widht;
  max-width: $theme-view-widht;
  min-width: $theme-view-min-widht;
  .invite-banner {
    height: 450px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .fake-img {
      position: absolute;
      top: 34%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 730px;
      height: 613px;
    }
    .invite-info {
      z-index: 1;
      text-align: center;
      .ii-title {
        font-weight: 700;
        font-size: 50px;
        color: #414141;
      }
      .ii-descriptions {
        margin: 22px 0;
        font-weight: 400;
        font-size: 24px;
        color: #444343;
        .color-style {
          color: #E62129;
        }
      }
    }
  }
  .invite-main {
    position: relative;
    // margin: 0 auto;
    // width: $theme-view-widht;
    min-height: 50px;
    .invite-flow {
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // width: 100%;
      text-align: center;
      .flow-head {
        box-sizing: border-box;
        padding: 0 140px;
        .flow-title {
          box-sizing: border-box;
          padding: 50px 0;
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 24px;
          color: #444343;
          .title-text {
            box-sizing: border-box;
            padding: 0 37px;
          }
          &::after,
          &::before {
            content: '';
            display: block;
            height: 1px;
            background: #E8C176;
            flex: 1;
            min-width: 0;
          }
        }
      }
      .flow-continer {
        display: inline-block;
        width: auto;
        margin: 0 auto;
      }
      .flow-form {
        $icon-size: 80px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 50px;
        border-radius: 30px 30px 0 0;
        overflow: hidden;
        // background: #fff;
        // box-shadow: 0 0 27px -7px rgba(0, 0, 0, 0.3);
        .ff-item {
          display: flex;
          // align-items: center;
          .ff-icon {
            .icon-box {
              margin: 0 auto;
              width: $icon-size;
              height: $icon-size;
              line-height: $icon-size;
              text-align: center;
              color: #fff;
              background-repeat: no-repeat;
              background-size: 100%;
              .iconfont {
                font-size: 32px;
              }
            }
            .icon-name {
              margin-top: 16px;
              font-size: 20px;
              color: #444343;
            }
          }
          .ff-arrow {
            width: 50px;
            height: 20px;
            margin: 0 50px;
            margin-top: calc((#{$icon-size} / 2) - (20px / 2));
          }
        }
      }
    }
  }
  .invite-details {
    margin-top: 10px;
    text-align: center;
    .invite-qrcode {
      position: relative;
      width: 300px;
      height: 310px;
      font-size: 0;
      margin: 0 auto;
      &::before{
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .fake-img {
        width: 100%;
        height: 100%;
      }
      .qr-photo {
        box-sizing: border-box;
        border-radius: 40px;
        overflow: hidden;
        z-index: 4;
        position: absolute;
        top: 34px;
        left: 50%;
        transform: translateX(-50%);
        width: 162px;
        height: 162px;
      }
    }
    .confirm {
      margin: 30px auto;
      display: flex;
      justify-content: center;
      .confirm-item {
        width: 240px;
        height: 46px;
        margin: 0 24px;
        .fake-btn {
          width: 100%;
          height: 100%;
          font-weight: 600;
          font-size: 18px;
          color: #fff;
          border-radius: 60px;
          border: 1px solid transparent;
          // background: $common-number1-color;
          background: linear-gradient(#E62129, #F9434B);
          outline: none;
          &.el-button:hover,&.el-button:focus,&.el-button:active {
            color: none;
            border-color: none;
            background-color: none;
          }
          & span {
            cursor: pointer;
          }
          .iconfont {
            margin-right: 6px;
          }
        }
      }
    }
    .activity-rules {
      margin: 16px 0;
      .ar-text {
        font-size: 12px;
        color: #444343;
        .iconfont {
          font-size: 12px;
          margin-left: 6px;
        }
      }
    }
  }
  .copyright-tips {
    margin: 100px auto;
    text-align: center;
    font-size: 12px;
    color: #444343;
  }
}
</style>
